import { useEffect } from 'react';
import { useIntersection } from '@buzzfeed/react-components';
import { useTrackingContext } from './useTrackingContext';

export const useViewImpression = (
  {
    trackingData = {},
    options = {},
    condition = true, // optional impression tracking
  },
  /**
   * Allow the caller to specify dependencies, giving them more control over when the effect should
   * run. This is useful when the caller wants to track impressions based on a specific condition
   * that is not directly related to the intersecting state. Use case example, firing an
   * impression on a carousel slides when the slide index changes.
   */
  dependencies = []
) => {
  const { trackImpression } = useTrackingContext();
  const { isIntersecting, setObservable } = useIntersection({
    threshold: 0.5,
    once: true,
    ...options,
  });

  useEffect(() => {
    if (isIntersecting && condition) {
      trackImpression(trackingData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntersecting, ...dependencies]);

  return { isIntersecting, setObservable };
};
