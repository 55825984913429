/**
 * Rendering for object of type 'bfp_content' from feed API system
 */
import { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { loadScript } from '@buzzfeed/bf-utils/lib/load-script';
import EmojiReactions from '../EmojiReactions';
import { TrackingContext } from '../../contexts';
import { CLUSTER, LOCAL_BFPS } from '../../constants';
import styles from './BFPContent.module.scss';

const BFPContent = ({
  className,
  contentObjectId,
  content = {},
  trackingData, // tracking data about the specific position on the page
}) => {
  // tracking info about the page, which certain BFPS may need if they have CET
  const {
    context_page_id,
    context_page_type,
    destination,
    page_edition
  } = useContext(TrackingContext);

  const fullTrackingData = {
    context_page_id,
    context_page_type,
    destination,
    page_edition,
    ...trackingData
  };

  const reactionsTrackingData = {
    ...fullTrackingData,
    ...(fullTrackingData.item_name ? {} : { item_name: contentObjectId }),
  };

  const bfpRef = useRef(null);

  useEffect(() => {
    loadBfpClient();
  }, []);

  const loadBfpClient = async () => {
    let renderKitUrl = '';
    let localBfps = [];

    try {
      localBfps = LOCAL_BFPS.split(',') || [];
    } catch (e) {}

    if (localBfps.includes(content?.bfp?.format_name)) {
      const format = content?.bfp.format_name.replace('_','-');
      const response = await fetch(`/api/local-bfp?format=${format}`)
      const responseJson = await response.json();
      renderKitUrl = responseJson.renderKitUrl;
    } else {
      const renderKit = content?.bfp?.render_kit?.find((kit) => {
        return kit?.url?.includes('render_kit.'); // web render kit
      });
      renderKitUrl = `https://${CLUSTER === 'prod' ? 'www' : 'stage'}.buzzfeed.com${renderKit?.url}`;
    }

    if (renderKitUrl) {
      await loadScript(renderKitUrl);

      const formatName = content?.bfp.format_name;
      if (bfpRef?.current && window[`bfp_${formatName}`]) {
        try {
          window[`bfp_${formatName}`].init({
            element: bfpRef.current,
            config: {
              data: content.bfp.data,
              context: {
                env: CLUSTER,
                clientEventTracking: fullTrackingData,
                consumer: 'feed_ui',
                contentObjectId,
                bfpId: content.bfp.id,
              }
            },
            broadcast: () => {}
          })
        } catch (e) {
          console.error(`Unable to load BFP - ${e.message}`)
        }
      }
    }
  }

  // server side HTML for rendering; may not always exist, but if it does, should show
  const html = content?.bfp?.renders?.oo_web;
  const classNames = [
    className,
    styles[content?.bfp?.format_name],
  ].filter(Boolean).join(' ');

  return (
    <article
      data-name="bfp-wrapper"
      className={classNames}
      /**
       * This label is generic enough to cover various types of content that might be created with
       * BFP, and specific enough to give screen reader users an idea of what the container is for.
       */
      aria-label="Interactive content container"
    >
      {/**
        * Consistent JSON script with tracking data that individual BFPs can access
        * for contextual information about the specific location on page for CET tracking.
        * If using this component on the page, make sure to always provide this tracking info
        */}
      <script
        data-name="tracking-data"
        type="application/json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(fullTrackingData)}}
      />
      <div className={styles.container} ref={bfpRef} aria-live="polite">
        {html && <div dangerouslySetInnerHTML={{__html: html}}/>}
      </div>
      {/* Disable reactions only if allow_reactions has been explicitly defined as false. */}
      {content.allow_reactions === false
        ? null
        : <EmojiReactions
            className={styles.reactions}
            contentId={contentObjectId}
            data={content.content_reactions}
            isTrackable={true}
            trackingData={reactionsTrackingData}
          />
      }
    </article>
  );
};

BFPContent.propTypes = {
  className: PropTypes.string,
  content: PropTypes.object.isRequired,
  trackingData: PropTypes.object,
};

export default BFPContent;
