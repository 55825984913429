import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import AdsProvider from '@buzzfeed/adlib/dist/module/bindings/react/components/AdsContext';
import { getPageContext } from '../../utils/ads/context';
import useABeagleAsync from '../../hooks/useABeagleAsync';
// TODO: FInd a way to remove this dependency?
import useLegacyTracking from '../../hooks/tracking/useLegacyTracking';

/* eslint-disable camelcase */
async function getPageTargeting({ pageName, pagePath }) {
  const pageTargeting = pageName !== 'topic' ? {
    pagetype: 'A',
    destination: ['newhp', 'buzzfeedhomepage', 'buzzfeed'],
  } : {
    destination: ['topicpage'],
    topic: pagePath,
  };

  return pageTargeting;
}
/* eslint-enable camelcase */

export default function Ads({
  edition = 'en-us',
  pagePath = 'hp',
  pageName,
  children,
}) {
  const abeagle = useABeagleAsync();
  const legacyTracking = useLegacyTracking();

  const getPageContextBound = useCallback(
    () =>
      getPageContext({ edition, abeagle, tracking: legacyTracking, pageName }),
    [edition, abeagle, legacyTracking, pageName]
  );
  const getPageTargetingBound = useCallback(
    () => getPageTargeting({ pageName, pagePath }),
    [abeagle]
  );

  return (
    <AdsProvider
      pageId={pagePath}
      pageLanguage={edition}
      adsEnabled={true}
      getPageContext={getPageContextBound}
      getPageTargeting={getPageTargetingBound}
    >
      {children}
    </AdsProvider>
  );
}

Ads.propTypes = {
  edition: PropTypes.string,
  pagePath: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
