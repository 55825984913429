import { useContext } from 'react';
import Head from 'next/head';
import { SiteHeader } from '@buzzfeed/react-components';
import { StickyContext } from '../../contexts';

// this cookie required to get the bf_header_ui to work...
// an imported module w/in the header service relies on this
const WINDOW_GLOBALS = `
  window.BZFD = window.BZFD || {};
  window.BZFD.Config = {
    bfwInfoCookie: 'bf2-b_info'
  };
`;

export function HomepageSiteHeader({
  html = '',
  js = '',
  css,
  communityPage = false,
}) {
  const stickyRegistry = useContext(StickyContext);
  return (
    <>
      <Head>
        <script
          key="window-globals"
          dangerouslySetInnerHTML={{ __html: WINDOW_GLOBALS }}
        />
      </Head>
      {communityPage ? (
        <SiteHeader html={html} js={js} css={css} />
      ) : (
        <SiteHeader
          html={html}
          js={js}
          css={css}
          stickyRegistry={stickyRegistry}
        />
      )}
    </>
  );
}

export default HomepageSiteHeader;
