import React from 'react';
import FeedCard from '../index';
import ImagePlaceholder from '../../../static/img/image-placeholder.svg';
import styles from './singleCard.module.scss';

export const SingleCard = ({
  className = '',
  index,
  content,
  popularLabel = '',
  isTrackable = false,
  trackingData = {},
  showSection = false,
}) => {
  const baseTrackingData = {
    item_type: 'card',
    item_name: content?.id,
    target_content_type: 'buzz',
    target_content_id: content?.id,
    ...trackingData,
    ...(content?.trackingData || {}),
  };

  if (!content) return null;

  const cardClassName = [
    styles.feedArticle,
    styles[className]
    ].join(' ');

  return (
    <FeedCard
      className={cardClassName}
      isTrackable={isTrackable}
      trackingData={baseTrackingData}
    >
      {(showSection && !!content.category?.label?.length) && <FeedCard.Section className={styles.category}>{content.category.label}</FeedCard.Section>}
      <div className={styles.thumbnailWrapper}>
        <FeedCard.Link
          key="imageLink"
          url={content.url}
          isTrackable={isTrackable}
          trackingData={baseTrackingData}
        >
          <FeedCard.Thumbnail
            thumbnail={content.thumbnail?.standard?.url || ImagePlaceholder}
            alt={String(content.thumbnail?.standard?.alt ?? '')}
            className={styles.thumbnail}
          />
        </FeedCard.Link>
        {index && <FeedCard.IndexLabel className={styles.index}>{index}</FeedCard.IndexLabel >}
      </div>
      <div className={styles.textWrapper}>
        <FeedCard.InfoLabel
          className={styles.infoLabel}
          createdAt={content.timestamp}
          label={content.isPopular ? 'Popular' : popularLabel}
        />
        <FeedCard.Link
          key="headingLink"
          url={content.url}
          isTrackable={isTrackable}
          trackingData={baseTrackingData}
        >
          <h2 className={styles.heading}>{content.title}</h2>
        </FeedCard.Link>
        <div className={styles.statsWrapper}>
          <FeedCard.Reactions
            className={styles.reactions}
            contentId={Number(content.id)}
            data={content.contentReactions}
            isTrackable={isTrackable}
            trackingData={baseTrackingData}
          />
          {content.comments?.countFormatted &&
            <FeedCard.Link
              className={styles.statsLink}
              key="statsLink"
              url={content.url}
              isTrackable={isTrackable}
              trackingData={baseTrackingData}
            >
              <FeedCard.Stats
                className={styles.statistics}
                commentsCount={content.comments.countFormatted}
              />
            </FeedCard.Link>
          }
        </div>
      </div>

    </FeedCard>
  );
};

export default SingleCard;
